<template>
  <section class="space-1 bg-light sign-up-section">
    <div class="container">
      <div class="d-flex justify-content-center mb-5">
        <router-link to="/">
          <img class="logo d-flex"
               src="@/assets/img/brand/logo.svg" alt="Logo">
        </router-link>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-5 card card-body py-5 px-5">
          <div v-show="!signedUp" class="px-3 py-3">
            <div class="mb-4">
              <h3>Sign Up!</h3>
              <p class="text-muted">
                The simplest way to keep track of stuff!</p>
            </div>
            <form>
              <div class="form-group">
                <label for="email">Email address</label>
                <input type="email" class="form-control" id="email" v-model="username"
                       v-on:keyup.enter="signUpUserFromTextbox()"
                       aria-describedby="emailHelp" required>
                <small v-if="isEmailValid" id="emailHelp"
                       class="form-text text-muted">
                  We'll never share your email with anyone else.</small>
                <small v-if="!isEmailValid" class="form-text red">
                  Hmm... That does not look like a valid email!
                </small>
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <input type="password" v-model="password" class="form-control"
                       v-on:keyup.enter="signUpUserFromTextbox()"
                       id="password" required>
                <small v-if="!isPasswordValid" class="form-text red">
                  The password needs to be at least 8 characters in length.</small>
              </div>
              <div class="form-group">
                <label for="repassword">Confirm password</label>
                <input type="password" v-model="rePassword" class="form-control"
                       v-on:keyup.enter="signUpUserFromTextbox()"
                       id="repassword" required>
                <small v-if="!arePasswordsSame" class="form-text red">
                  The passwords need to match.</small>
              </div>
              <p class="small mt-3">
                By creating an account, you are agreeing to our
                <router-link target="_blank" to="/terms">Terms of Service</router-link> and
                <router-link target="_blank" to="/privacy">Privacy Policy</router-link>.
              </p>
              <button type="button" @click="registerUser()"
                      :disabled="isSignupButtonDisabled"
                      class="btn btn-primary btn-block">Sign Up</button>

              <p class="small mt-3 text-center text-muted">
                This site is protected by reCAPTCHA and the Google
                <a target="_blank" href="https://policies.google.com/privacy">Privacy Policy</a> and
                <a target="_blank" href="https://policies.google.com/terms">Terms of Service</a> apply.
              </p>

            </form>
            <div v-if="processing" class="progress-line blue-bar"></div>
            <div class="mt-5 text-center">
              <p class="m-0">Already have an account?</p>
              <router-link to="/login">Login</router-link>
            </div>
          </div>
          <div v-show="signedUp" class="px-3 py-3">
            <div class="mb-4">
              <h3><i class="fad fa-glass-cheers"></i>&nbsp;
                Congratulations!</h3>
              <div v-if="!verificationEmailSent">
                <p class="text-muted">
                  Everything is set. You can now login and start using trbo!</p>
                <router-link class="btn btn-primary wide" to="/login">
                  <i class="fa fa-rocket-launch"></i>&nbsp;
                  Login now</router-link>
              </div>
              <div v-if="verificationEmailSent">
                <p class="text-muted">
                  We sent you an email with instructions to verify your email address.
                  Please check your email now (including spam).
                </p>
                <a v-if="emailProviderLink != null"
                   class="btn btn-primary wide" :href="emailProviderLink">
                  <i class="fa fa-envelope-open-text"></i>&nbsp;
                  Check your email now</a>
              </div>
            </div>
            <div class="mb-4">
              <img class="onboarding-image"
                   style="width: 100%"
                   src="@/assets/img/signup/awesome.webp" alt="You are awesome!" />
              <div class="image-attribution text-left">
                Colin Mochrie / Whose line is it anyway?</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import utils from '@/app/utils';
import userInfo from '@/app/user-info';
import { load } from 'recaptcha-v3';

const SITE_KEY = process.env.VUE_APP_CAPTCHA_3_CLIENT_KEY;

export default {
  components: { },
  computed: {
    isEmailValid() {
      if (this.username != null && this.username.trim() === '') {
        return true;
      }

      return utils.validateEmail(this.username);
    },
    isPasswordValid() {
      if (this.password == null || this.password.trim() === '') {
        return true;
      }

      return this.password.length >= 8;
    },
    arePasswordsSame() {
      // Check only after the first password is valid
      if (!this.isPasswordValid) {
        return true;
      }

      // Skip check if user has not entered the 2nd password.
      if (this.rePassword == null || this.rePassword.trim() === '') {
        return true;
      }

      return this.password === this.rePassword;
    },
    isSignupButtonDisabled() {
      if (this.username == null || this.password == null || this.rePassword == null
          || this.username.trim() === '' || this.password.trim() === '' || this.rePassword.trim() === '') {
        return true;
      }

      return !this.isEmailValid || !this.isPasswordValid
          || !this.arePasswordsSame || this.processing;
    },
  },
  data() {
    return {
      processing: false,
      username: '',
      password: '',
      rePassword: '',
      signedUp: false,
      verificationEmailSent: false,
      emailProviderLink: null,
    };
  },
  methods: {
    async registerUser() {
      try {
        this.processing = true;

        const recaptcha = await load(SITE_KEY, {
          autoHideBadge: true,
        });
        const token = await recaptcha.execute('signup');

        // Sign up new user
        const newUser = await userInfo.signUpNewUser(this.username, this.password, token);
        this.verificationEmailSent = newUser.verification_email_sent;
        if (this.verificationEmailSent) {
          this.emailProviderLink = utils.fetchEmailProvidersLink(this.username);
        }
        this.signedUp = true;

        // See if it is an email provider we support
        this.processing = false;
      } catch (error) {
        this.processing = false;

        if (error.response && error.response.data && error.response.data.code) {
          const errorCode = error.response.data.code;
          const errorMessage = error.response.data.message;

          if (errorCode === 'EMAIL_ALREADY_EXISTS') {
            utils.showToastError('Email already exists',
              'An account with this email already exists. Try logging in instead?',
              true);
            return;
          } if (errorCode === 'INVALID_PASSWORD') {
            utils.showToastError('Password is Invalid',
              errorMessage,
              true);
            return;
          } if (errorCode === 'INVALID_EMAIL') {
            utils.showToastError('Invalid Email',
              'Please enter a valid email address to continue.',
              true);
          } else {
            utils.showToastError('Could not create account',
              'An error occurred and your account could not be created. '
                + 'Please refresh the page and try again.',
              true);
          }
        } else {
          console.log(error);
        }
      }
    },
    async signUpUserFromTextbox() {
      if (!this.isSignupButtonDisabled) {
        await this.registerUser();
      }
    },
  },
};
</script>
