/* eslint-disable no-unused-vars */
import axios from 'axios';
import utils from '@/app/utils';

/** *
 * Fetches the user's details from the specified token
 * @param token
 * @return {Promise<any>}
 */
const fetchUser = async (token) => {
  const url = `${process.env.VUE_APP_API_URL}/user`;
  const result = axios.get(url, utils.getAxiosConfig(token));
  return (await result).data;
};

/** *
 * Updates the timezone for the logged in user.
 * @param token
 * @param timezone
 * @return {Promise<void>}
 */
const updateTimezone = async (token, timezone) => {
  const url = `${process.env.VUE_APP_API_URL}/user/timezone`;
  const data = {
    new_time_zone: timezone,
  };

  const result = axios.post(url, data, utils.getAxiosConfig(token, true));
  return (await result).data;
};

/** *
 * Sets the on boarding flag for a user
 * @param token
 * @param onBoardingStatus
 * @return {Promise<any>}
 */
const setOnBoardingFlag = async (token, onBoardingStatus) => {
  const url = `${process.env.VUE_APP_API_URL}/user`;
  const data = {
    on_boarded: onBoardingStatus,
  };

  const result = axios.patch(url, data, utils.getAxiosConfig(token, true));
  return (await result).data;
};

/** *
 * Changes the user's password
 * @param token
 * @param oldPassword
 * @param newPassword
 * @param accessToken
 * @return {Promise<any>}
 */
const changePassword = async (token, oldPassword, newPassword, accessToken) => {
  const url = `${process.env.VUE_APP_API_URL}/user/password`;
  const data = {
    old_password: oldPassword,
    new_password: newPassword,
    access_token: accessToken,
  };

  const result = axios.post(url, data, utils.getAxiosConfig(token, false));
  return (await result).data;
};

/** *
 * Generates a random bunch of numbers to allow us to easily link a user email to a telegram
 * account
 * @param token
 * @return {Promise<any>}
 */
const fetchTelegramConnectionCode = async (token) => {
  const url = `${process.env.VUE_APP_API_URL}/user/connect_telegram`;
  const data = {}; // No data required for this. Just a POST request ¯\_(ツ)_/¯

  const result = axios.post(url, data, utils.getAxiosConfig(token, true));
  return (await result).data;
};

/** *
 * Disconnects trbo connection from Telegram
 * @param token
 * @return {Promise<any>}
 */
const disconnectFromTelegram = async (token) => {
  const url = `${process.env.VUE_APP_API_URL}/user/disconnect_telegram`;
  const data = {}; // No data required for this. Just a POST request ¯\_(ツ)_/¯

  const result = axios.post(url, data, utils.getAxiosConfig(token, true));
  return (await result).data;
};

/** *
 * Updates reminder preferences for the user.
 * @param token
 * @param remindEmail
 * @param remindTelegram
 * @return {Promise<any>}
 */
const updateUserReminderPreferences = async (token, remindEmail, remindTelegram) => {
  const url = `${process.env.VUE_APP_API_URL}/user/reminder_settings`;
  const data = {
    email_reminder: remindEmail,
    telegram_reminder: remindTelegram,
  };

  const result = axios.post(url, data, utils.getAxiosConfig(token, true));
  return (await result).data;
};

/** *
 * Signs up a new user to the system
 * @param email
 * @param password
 * @param captchaToken
 * @return {Promise<any>}
 */
const signUpNewUser = async (email, password, captchaToken) => {
  const data = { email, password, token: captchaToken };
  const url = `${process.env.VUE_APP_API_URL}/user/signup`;

  const result = axios.post(url, data);
  return (await result).data;
};

/** *
 * Sends the verification email to the logged in user.
 * @param token
 * @return {Promise<any>}
 */
const sendVerificationEmail = async (token) => {
  const url = `${process.env.VUE_APP_API_URL}/user/email/send_code`;
  const data = {}; // No data to send

  const result = axios.post(url, data, utils.getAxiosConfig(token, false));
  return (await result).data;
};

/** *
 * Verifies the email for the user.
 * @param email
 * @param code
 * @return {Promise<void>}
 */
const verifyEmail = async (email, code) => {
  const url = `${process.env.VUE_APP_API_URL}/user/email/verify`;
  const data = { email, code };

  const result = axios.post(url, data);
  return (await result).data;
};

/** *
 * Sends a new password reset email to the user
 * @param email
 * @param captcha
 * @return {Promise<any>}
 */
const requestPasswordReset = async (email, captcha) => {
  const url = `${process.env.VUE_APP_API_URL}/user/password/request_reset`;
  const data = { email, captcha };

  const result = axios.post(url, data);
  return (await result).data;
};

/** *
 * Resets the user's password with a new specified password
 * @param userId
 * @param token
 * @param password
 * @return {Promise<any>}
 */
const resetUserPassword = async (userId, token, password) => {
  const url = `${process.env.VUE_APP_API_URL}/user/password/reset`;
  const data = { user_id: userId, token, password };

  const result = axios.post(url, data);
  return (await result).data;
};

/** *
 * Creates a new journal
 * @param token
 * @param name
 * @param code
 * @return {Promise<any>}
 */
const createNewJournal = async (token, name, code) => {
  const url = `${process.env.VUE_APP_API_URL}/user/journal`;
  const data = {};
  data.name = name;
  data.code = code;

  const result = axios.post(url, data, utils.getAxiosConfig(token));
  return (await result).data;
};

/** *
 * Updates journal details for a user
 * @param token
 * @param id {string} The Journal ID
 * @param name
 * @param code
 * @return {Promise<any>}
 */
const updateJournal = async (token, id, name, code) => {
  const url = `${process.env.VUE_APP_API_URL}/user/journal`;
  const data = { id, name, code };

  const result = axios.put(url, data, utils.getAxiosConfig(token));
  return (await result).data;
};

/** *
 * Deletes the specified Journal
 * @param token
 * @param id {string} The Journal ID
 * @return {Promise<any>}
 */
const deleteJournal = async (token, id) => {
  const url = `${process.env.VUE_APP_API_URL}/user/journal/${id}`;

  const result = axios.delete(url, utils.getAxiosConfig(token));
  return (await result).data;
};

export default {
  updateTimezone,
  fetchUser,
  changePassword,
  signUpNewUser,
  setOnBoardingFlag,
  fetchTelegramConnectionCode,
  disconnectFromTelegram,
  updateUserReminderPreferences,
  sendVerificationEmail,
  verifyEmail,
  requestPasswordReset,
  resetUserPassword,
  createNewJournal,
  updateJournal,
  deleteJournal,
};
